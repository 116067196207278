import * as React from 'react';
import { __ } from 'react-i18n/lib';
import {
  RegistrationHeading,
  RegistrationErrorPopup,
  RegistrationInput,
  RegistrationSelect,
  RegistrationLabel,
  RegistrationFormCol,
  RegistrationFormRow,
  RegistrationEmailInput,
} from './styles';
import { Field, Form } from 'react-final-form';
import { Loader } from '../_helpers/Loader/Loader';
import {
  composeValidators,
  email,
  emailExist,
  phone,
  phoneMobile,
  postalCode,
  required,
  street,
} from '../../utilities/validators';
import {
  onRegistrationSubmit,
  RegistrationProps,
  renderRegistrationAlert,
  renderRegistrationPasswordInputs,
  renderRegistrationSubmitButton,
  renderRegistrationTermsCheckbox,
} from './constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  detectCustomerCard,
  loadRetailIntroLess,
  loadRetailIntroMore,
} from '../../containers/Registration/action';
import { OtherText, URL_REGISTRATION_COMPLETE_RETAIL } from '../../constants';
import RegistrationIntro from './RegistrationIntro';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import {
  currentYear,
  debounce,
  formatBirthDay,
  isEmailValid,
  loadGlobalMetaTags,
  range,
} from '../../utilities';
import { FormApi } from 'final-form';
import styled from 'styled-components';
import { rem } from 'polished';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import MetaTags from '../MetaTags/MetaTags';
import { parsePostalCode } from '../../utilities/formParsers';

const FormSubWrapper = styled.div`
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${({ theme }) => theme.media('xs')} {
    width: 50%;
  }
  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const FormSubSmallCol = styled.div`
  flex: 2;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    margin-right: ${rem(10)};
  }
  ${({ theme }) => theme.media('md')} {
    margin-bottom: 0;
  }
`;

const metaUid = 'REG_RETAIL';

const emailChanged = (dispatch, email) => dispatch(detectCustomerCard(email));
const [recognizeCustomerCardDebounce] = debounce(emailChanged, 1000);

interface RegistrationRetailProps {
  retailIntroLess: OtherText;
  retailIntroMore: OtherText;
  hasCustomerCardDetect: boolean;
  globalMetaTags: any;
}

const RegistrationRetail = (
  props: RegistrationProps & RegistrationRetailProps,
) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(undefined);
  const [showPassword, setShowPassword] = React.useState(false);
  const handlePasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  const {
    dispatch,
    retailIntroLess,
    retailIntroMore,
    hasCustomerCardDetect,
    globalMetaTags,
  } = props;

  const year = currentYear();

  const onSubmit = async (values) =>
    onRegistrationSubmit(
      dispatch,
      setSubmitting,
      setSubmitError,
      {
        email: values.email,
        login: values.email,
        password: values.password,
        password_again: values.password_confirm,
        skvelko_type: ChematCustomerType.RETAIL,
        customer_card: true, //values.customer_card_accept,
        customer_card_send: values.send_card_accept,
        customer_card_name: values.customer_card_name,
        customer_card_surname: values.customer_card_surname,
        customer_card_phone: values.customer_card_phone,
        customer_card_birth: formatBirthDay(
          values.customer_card_day,
          values.customer_card_month,
          values.customer_card_year,
        ),
        customer_card_street: values.customer_card_street,
        customer_card_street_number: values.customer_card_street_number,
        customer_card_town: values.customer_card_city,
        customer_card_zip: values.customer_card_postal_code,
        customer_card_age_approval: values.min_age_accepted,
        customer_card_card_approval: values.gdpr_card_accepted,
        customer_card_email_approval: values.gdpr_weekend_emails_accepted,
        customer_card_profile_approval: values.gdpr_profil_accepted,
        lang: 'sk',
      },
      URL_REGISTRATION_COMPLETE_RETAIL,
    );

  const renderEmailInput = () => (
    <RegistrationFormRow>
      <Field
        name="email"
        validate={composeValidators(required, email, emailExist)}
      >
        {({ input, meta }) => (
          <RegistrationFormCol>
            <RegistrationLabel htmlFor="email">
              {__('Váš email')}:
            </RegistrationLabel>
            <RegistrationEmailInput
              {...input}
              type="email"
              onChange={(e) => {
                const inputValue = e.target.value;
                input.onChange(e);
                if (isEmailValid(inputValue)) {
                  recognizeCustomerCardDebounce(dispatch, inputValue);
                }
              }}
            />
            {meta.error && meta.touched && (
              <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
            )}
          </RegistrationFormCol>
        )}
      </Field>
    </RegistrationFormRow>
  );

  const renderCustomerCardAcceptCheckbox = (form: FormApi) => (
    <>
      {/* 
      <RegistrationFormRow>
        <Field name="customer_card_accept" type="checkbox">
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled {...input} value="1" id="customer_card_accept">
                <span>
                  {__('Mám záujem o')} <strong>{__('zľavu 20%')}</strong>{' '}
                  {__('na prvý nákup a')} <strong>{__('zľavu 5%')}</strong>{' '}
                  {__('na ďalšie nákupy zaslaním')}{' '}
                  <a onClick={openCustomerCardModal.bind(null, dispatch)}>
                    {__('Zákaznickej kartičky')}
                  </a>
                  {__(' do emailu')}
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      
      {form.getFieldState('customer_card_accept')?.value &&
        renderCustomerCardSendCheckbox(form)}
      */}

      {renderCustomerCardSendCheckbox(form)}
    </>
  );

  const renderCustomerCardSendCheckbox = (form: FormApi) => (
    <>
      <RegistrationFormRow>
        <Field name="send_card_accept" type="checkbox">
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled {...input} value="1" id="send_card_accept">
                <span>
                  {__(
                    'Zašlite mi plastovú Zákaznícku kartičku na adresu domov. (Plastová kartička slúži na nákup v kamenných predajniach.)',
                  )}
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      {form.getFieldState('send_card_accept')?.value &&
        renderCustomerCardForm()}
    </>
  );

  const renderCustomerCardForm = () => (
    <>
      <RegistrationHeading>{__('Osobné údaje')}:</RegistrationHeading>
      <RegistrationFormRow>
        <Field name="customer_card_name" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_name">
                {__('Meno')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
        <Field name="customer_card_surname" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_surname">
                {__('Priezvisko')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field
          name="customer_card_phone"
          validate={composeValidators(phone, phoneMobile, required)}
        >
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_phone">
                {__('Telefón')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
        <FormSubWrapper>
          <RegistrationLabel className={'full-width'}>
            {__('Dátum narodenia')}:
          </RegistrationLabel>
          <FormSubSmallCol>
            <Field name="customer_card_day" validate={required}>
              {({ input, meta }) => (
                <>
                  <RegistrationSelect {...input}>
                    <option value="">{__('deň')}</option>
                    {range(1, 32).map((day) => (
                      <option value={day} key={day}>
                        {day}.
                      </option>
                    ))}
                  </RegistrationSelect>
                  {meta.error && meta.touched && (
                    <RegistrationErrorPopup>
                      {meta.error}
                    </RegistrationErrorPopup>
                  )}
                </>
              )}
            </Field>
          </FormSubSmallCol>
          <FormSubSmallCol>
            <Field name="customer_card_month" validate={required}>
              {({ input, meta }) => (
                <>
                  <RegistrationSelect {...input}>
                    <option value="">{__('mesiac')}</option>
                    {range(1, 12).map((month) => (
                      <option value={month} key={month}>
                        {month}.
                      </option>
                    ))}
                  </RegistrationSelect>
                  {meta.error && meta.touched && (
                    <RegistrationErrorPopup>
                      {meta.error}
                    </RegistrationErrorPopup>
                  )}
                </>
              )}
            </Field>
          </FormSubSmallCol>
          <FormSubSmallCol>
            <Field name="customer_card_year" validate={required}>
              {({ input, meta }) => (
                <>
                  <RegistrationSelect {...input}>
                    <option value="">{__('rok')}</option>
                    {range(year - 100, year)
                      .reverse()
                      .map((yearr) => (
                        <option value={yearr} key={yearr}>
                          {yearr}
                        </option>
                      ))}
                  </RegistrationSelect>
                  {meta.error && meta.touched && (
                    <RegistrationErrorPopup>
                      {meta.error}
                    </RegistrationErrorPopup>
                  )}
                </>
              )}
            </Field>
          </FormSubSmallCol>
        </FormSubWrapper>
      </RegistrationFormRow>
      <RegistrationHeading>
        {__('Adresa pre doručenie kartičky')}:
      </RegistrationHeading>
      <RegistrationFormRow>
        <Field
          name="customer_card_street"
          validate={composeValidators(required, street)}
        >
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_street">
                {__('Ulica')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
        <Field name="customer_card_street_number" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_street_number">
                {__('Číslo domu')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field name="customer_card_city" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_city">
                {__('Mesto / obec')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
        <Field
          name="customer_card_postal_code"
          validate={composeValidators(required, postalCode)}
          parse={parsePostalCode}
        >
          {({ input, meta }) => (
            <RegistrationFormCol>
              <RegistrationLabel htmlFor="customer_card_postal_code">
                {__('PSČ')}:
              </RegistrationLabel>
              <RegistrationInput {...input} type="text" />
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field name="min_age_accepted" type="checkbox" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled {...input} value="1" id="min_age_accepted">
                <span>
                  {__('Potvrdzujem, že som už dovŕšil vek 16 rokov')}.
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field name="gdpr_card_accepted" type="checkbox" validate={required}>
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled {...input} value="1" id="gdpr_card_accepted">
                <span>
                  {__(
                    'Súhlasím so spracovaním osobných údajov za účelom vystavenia zákazníckej karty Daffer',
                  )}
                  .
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field name="gdpr_weekend_emails_accepted" type="checkbox">
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled
                {...input}
                value="1"
                id="gdpr_weekend_emails_accepted"
              >
                <span>
                  {__(
                    'Súhlasím so spracovaním osobných údajov za účelom zasielania výhodných ponúk na moju emailovú adresu',
                  )}{' '}
                  ({__('nepovinné')}).
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
      <RegistrationFormRow>
        <Field name="gdpr_profil_accepted" type="checkbox">
          {({ input, meta }) => (
            <RegistrationFormCol>
              <CheckboxStyled {...input} value="1" id="gdpr_profil_accepted">
                <span>
                  {__(
                    'Súhlasím so spracovaním osobných údajov za účelom automatizovaného rozhodovania vrátane profilovania',
                  )}{' '}
                  ({__('nepovinné')}).
                </span>
              </CheckboxStyled>
              {meta.error && meta.touched && (
                <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
              )}
            </RegistrationFormCol>
          )}
        </Field>
      </RegistrationFormRow>
    </>
  );

  return (
    <>
      {globalMetaTags[metaUid] ? (
        <MetaTags tags={globalMetaTags[metaUid]} />
      ) : (
        <MetaTags tags={{ title: 'Daffer.sk - Registrácia' }} />
      )}
      <Loader loading={submitting}>
        <RegistrationHeading>{__('Výhody registrácie')}:</RegistrationHeading>
        <RegistrationIntro
          dispatch={dispatch}
          loadLess={loadRetailIntroLess}
          loadMore={loadRetailIntroMore}
          textLess={retailIntroLess}
          textMore={retailIntroMore}
        />
        <RegistrationHeading>{__('Registračný formulár')}:</RegistrationHeading>
        {renderRegistrationAlert(submitError)}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              {renderEmailInput()}

              {renderRegistrationPasswordInputs(
                handlePasswordIcon,
                showPassword,
              )}

              {!hasCustomerCardDetect && renderCustomerCardAcceptCheckbox(form)}

              {renderRegistrationTermsCheckbox(dispatch)}

              {renderRegistrationSubmitButton()}
            </form>
          )}
        />
      </Loader>
    </>
  );
};

RegistrationRetail.getInitialProps = async (parentProps) => {
  const { dispatch } = parentProps;
  const promises: any[] = [];

  promises.push(dispatch(loadGlobalMetaTags(metaUid)));

  await Promise.all(promises);
};

const mapStateToProps = (state) => {
  return {
    retailIntroLess: state.registration.retailIntroLess,
    retailIntroMore: state.registration.retailIntroMore,
    globalMetaTags: state.general.globalMetaTags,
    hasCustomerCardDetect: state.registration.hasCustomerCardDetect,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RegistrationRetail' })(RegistrationRetail),
);
