import * as React from 'react';
import { connect } from 'react-redux';
import { activeVariantByIdSelector } from './selectors';
import {
  addToFavouriteProducts,
  getLastSeenProducts,
  loadCurrentCategoryGroupByProductId,
  loadLastSeenProducts,
  loadProductById,
  loadProductUpsell,
  loadRelatedProducts,
  removeFromFavouriteProducts,
  setCurrentProductId,
  setLastSeenProducts,
  updateCurrentProductFavorite,
} from './actions';
import {
  CategoriesCol,
  Container,
  MainCol,
  Row,
} from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
  GalleryCol,
  ProductMetaCol,
  ProductRow,
} from '../../components/Product/ProductStyles';
import {
  ProductAlternatives,
  ProductBody,
  ProductDemandVariantSelect,
  ProductDescription,
  ProductDownloads,
  ProductFavorite,
  ProductForm,
  ProductGallery,
  ProductPrice,
  ProductShortDescription,
  ProductTitle,
  ProductVariantSelect,
} from '../../components/Product/Product';
import { __ } from 'react-i18n/lib';
import ProductsSlider from '../../components/_helpers/product/ProductsSlider';
import {
  loadFavoriteProductsCount,
  setAddToCartModalProduct,
  setArticleModal,
} from '../App/actions';
import {
  capitalizeFirstLetter,
  getGiftCategoryDesktopBannerFromSettings,
  getGiftWebContentIdFromSettings,
  isCartGiftsValid,
  isOutOfStock,
  prop,
  removeHtmlTags,
  scrollToElement,
  scrollToTop,
} from '../../utilities';
import MetaTags from '../../components/MetaTags/MetaTags';
import { productMetaHandler } from '../../utilities/product';
import styled from 'styled-components';
import { rem } from 'polished';
import { FacebookProvider, Like } from 'react-facebook';
import CategoriesListNew from '../../components/_helpers/Categories/CategoriesListNew';
import AvailabilityDetail from '../../components/_helpers/product/AvailabilityDetail';
import { Media, MediaContextProvider } from '../../theme/media';
import Tabs, { TabItem } from '../../components/Tabs/Tabs';
import { push } from 'react-router-redux';
import ConfirmModal from '../../components/_helpers/Modal/ConfirmModal';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import { sendViewItemEvent } from '../../utilities/ga4_events';
import {
  ButtonStyledStyles,
  ButtonStyles,
} from '../../components/_helpers/form/Button';
import Icon from '../../components/_helpers/Icon/Icon';
import { ProductUpsell } from './ProductUpsell';

const GiftImage = styled.img`
  width: 100%;
  margin-bottom: ${rem(10)};
  cursor: pointer;
`;

const FacebookWrapper = styled.div`
  margin-top: ${rem(24)};
  iframe,
  span {
    width: auto !important;
  }
`;

const ProductContainer = styled(Container)`
  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(20)};
  }
`;

const MainColStyled = styled(MainCol)`
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(16)};
  }
`;

const FavoriteMustLoginModal = styled(ChooseModal)``;

const FavoriteAddedModal = styled(ConfirmModal)``;

const FavoriteModalLink = styled.a`
  color: black;
  text-decoration: underline;
`;

const DescriptionButton = styled.a`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  width: fit-content;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  flex: 1 0 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: blue; /* For browsers that do not support gradients */
  background-image: linear-gradient(#4f97fe, #2880ff);
  outline: none;
  border: none;

  &:focus {
    border-color: none;
    box-shadow: none;
  }

  &:not(:disabled):hover {
    background: linear-gradient(#4f97fe, #2880ff);
  }
`;

const InfoIcon = styled(Icon)`
  fill: ${({ theme }) => theme.color.white};
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${rem(5)};
`;

const AlternativesWrapper = styled.div`
  margin-top: ${rem(10)};
`;

export interface ProductProps {
  product: any;
  connections: any[];
  dispatch: any;
  isCartProcessing: any;
  isError: boolean;
  lang: string;
  user: any;
  defaultTitle: string;
  token: string;
  setActiveVariant: any;
  setCurrentProductId: any;
  relatedProducts: any;
  loadedLastSeenProducts: any;
  cart: any;
  currentCategoryGroup: any;
  mainGroupCategory?: any;
  currentPath: string;
  settings?: any;
  upsell: any;
  currentCategoryId: number;
}

export interface AttribDemand {
  attrib_id: number;
  attrib_value_id: number;
  attrib_name: string;
  attrib_value_name: string;
}

export interface State {
  count: number;
  goodId: number | null;
  variationError: boolean;
  activeTab: number;
  favoriteMustLoginModalVisible: boolean;
  favoriteProductAddedModalVisible: boolean;
  allowedToAddToCart: boolean;
  attribObj: {};
  attribDemandObj: Array<AttribDemand>;
}

class Product extends React.Component<ProductProps, State> {
  public static async getInitialProps(props) {
    const { product_id, dispatch } = props;
    await dispatch(setCurrentProductId(product_id));
    await dispatch(loadProductById(product_id));
    await dispatch(loadCurrentCategoryGroupByProductId(product_id));
    await dispatch(loadRelatedProducts(product_id));
    await dispatch(loadProductUpsell(product_id));
    scrollToTop(); // Fix #1070
  }

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  public getDefaultState() {
    return {
      count: 1,
      goodId: null,
      variationError: false,
      activeTab: 0,
      favoriteMustLoginModalVisible: false,
      favoriteProductAddedModalVisible: false,
      allowedToAddToCart: false,
      attribObj: {},
      attribDemandObj: [],
    };
  }

  public onGiftOpen = async () => {
    this.props.dispatch(
      setArticleModal(getGiftWebContentIdFromSettings(this.props.settings)),
    );
  };

  public componentDidMount() {
    if (this.props && this.props.product && this.props.product.productDetail) {
      const product = this.props.product.productDetail;
      if (product.main_good) {
        sendViewItemEvent({
          value: product.main_good.final_price,
          items: [
            {
              item_id: product.product_id,
              item_name: product.name,
            },
          ],
        });
      }
    }
    this.props.dispatch(loadLastSeenProducts());
  }

  public async componentWillUpdate(prevProps) {
    /*
    if(this.props?.product?.productDetail?.productCategoriesBreadcrumbs?.length && this.props?.product?.productDetail?.productCategories?.length){
      const allCategoryIds = this.props?.product?.productDetail?.productCategories.map(pc => pc.category_id);
      if(allCategoryIds.length && !allCategoryIds.includes(this.props.currentCategoryId)){
        const lastCategoryId = this.props.product.productDetail.productCategoriesBreadcrumbs[this.props.product.productDetail.productCategoriesBreadcrumbs.length - 1].category_id;
        this.props.dispatch(setCurrentCategoryId(lastCategoryId));
      }
    }
    */
  }

  public async componentDidUpdate(prevProps) {
    if (this.props?.product?.currentProductId) {
      try {
        let localStorageSupported = 'localStorage' in window;
        if (localStorageSupported && localStorage) {
          const lastSeenProductsId: number[] = getLastSeenProducts();
          const newLastSeenProductsId: number[] = Array.from(
            new Set([
              this.props.product.currentProductId,
              ...lastSeenProductsId,
            ]),
          ).slice(0, 30);

          if (
            JSON.stringify(newLastSeenProductsId) !==
            JSON.stringify(lastSeenProductsId)
          ) {
            setLastSeenProducts(newLastSeenProductsId);
            this.props.dispatch(loadLastSeenProducts());
          }
        }
      } catch (e) {}
    }
  }

  public onAddFavouriteClick = (e) => {
    const { user } = this.props;
    const instance = this;

    if (user) {
      this.props.dispatch(
        addToFavouriteProducts(
          this.props.product.productDetail.product_id,
          user.id,
          function (response) {
            instance.props.dispatch(updateCurrentProductFavorite(true));
            instance.setState({
              favoriteMustLoginModalVisible: false,
              favoriteProductAddedModalVisible: true,
            });
            instance.props.dispatch(loadFavoriteProductsCount());
          },
        ),
      );
    } else {
      this.setState({
        favoriteMustLoginModalVisible: true,
        favoriteProductAddedModalVisible: false,
      });
    }
  };

  public onFavouriteCancel = (e) => {
    this.setState({
      favoriteMustLoginModalVisible: false,
      favoriteProductAddedModalVisible: false,
    });
  };

  public onFavouriteLogin = (e) => {
    this.props.dispatch(push('/prihlasenie'));
  };

  public onRemoveFavouriteClick = (e) => {
    const { user } = this.props;
    const instance = this;

    if (user) {
      this.props.dispatch(
        removeFromFavouriteProducts(
          this.props.product.productDetail.product_id,
          user.id,
          function (response) {
            instance.props.dispatch(updateCurrentProductFavorite(false));
            instance.props.dispatch(loadFavoriteProductsCount());
          },
        ),
      );
    }
  };

  public onCountChange = (count) => {
    this.setState({
      count: parseInt(count, 10),
    });
  };

  public onAddedToCart = async (bool) => {
    try {
      this.props.dispatch(
        setAddToCartModalProduct({
          product: this.props.product.productDetail,
          count: this.state.count,
          goodId: this.state.goodId,
        }),
      );
      this.setState({ variationError: false });
    } catch (e) {
      //
    }
  };

  public onVariationChange = (e) => {
    try {
      const { product } = this.props;
      const { attribObj } = this.state;
      const attribCount = product?.productDetail?.attribs?.length;
      const goods = product.productDetail?.goods;

      const selectedIndex = e.target.options.selectedIndex;
      const attribName = e.target.options[selectedIndex].getAttribute(
        'data-key',
      );
      const attribValue = e.target.value;

      attribObj[attribName] = attribValue;
      this.setState({ attribObj: attribObj });

      if (Object.keys(attribObj).length < attribCount) {
        this.setState({ allowedToAddToCart: false });
        this.setState({ attribObj: { ...this.state.attribObj, attribObj } });
      } else {
        let resGood;
        this.setState({ allowedToAddToCart: true });

        for (const good of goods) {
          if (good && good.web_attribs && good.web_attribs.length > 0) {
            let matchedAttribCount = 0;
            good.web_attribs.forEach((attrib) => {
              const attribName = attrib?.attrib_name;
              const attribValue = attrib?.values[0]?.attrib_value;

              if (attribObj[attribName] == attribValue) {
                matchedAttribCount += 1;
              }
            });
            if (matchedAttribCount == attribCount) {
              resGood = good;
              break;
            }
          }
        }

        if (resGood) {
          this.setState({ goodId: resGood.good_id });
          this.setState({ variationError: false });
        }
      }
    } catch (e) {}
  };

  public onDemandVariationChange = (e) => {
    const { product } = this.props;
    const { attribDemandObj } = this.state;
    const attribCount = product?.productDetail?.attribs?.length;

    const selectedIndex = e.target.options.selectedIndex;
    const attribId = e.target.options[selectedIndex].getAttribute('data-key');
    const attribValueId = e.target.value;

    const productAttrib = product?.productDetail?.attribs?.find(
      (attrib) => attrib.attrib_id == attribId,
    );
    const productattribValue = productAttrib?.values?.find(
      (value) => value.value_id == attribValueId,
    );

    let attribDemand = attribDemandObj.find((item) => {
      return item.attrib_id == attribId;
    });

    if (!attribDemand) {
      attribDemand = {
        attrib_id: parseInt(attribId),
        attrib_value_id: parseInt(attribValueId),
        attrib_name: productAttrib.attrib_name,
        attrib_value_name: productattribValue.attrib_value,
      };
      attribDemandObj.push(attribDemand);
    } else {
      (attribDemand.attrib_value_id = parseInt(attribValueId)),
        (attribDemand.attrib_name = productAttrib.attrib_name),
        (attribDemand.attrib_value_name = productattribValue.attrib_value);
    }

    this.setState({
      attribDemandObj: attribDemandObj.map((object) => ({ ...object })),
    });

    if (Object.keys(attribDemandObj).length !== attribCount) {
      this.setState({ allowedToAddToCart: false });
    } else {
      this.setState({ allowedToAddToCart: true });
    }
  };

  public setVariationError = (error) => {
    this.setState({ variationError: error });
  };

  public onTabChange = (id) => {
    this.setState({
      activeTab: id,
    });
  };

  public onDescriptionButtonClick = () => {
    scrollToElement('product-tabs', window.innerWidth > 1024 ? 200 : 50);
  };

  public render() {
    const {
      product,
      dispatch,
      cart,
      currentCategoryGroup,
      mainGroupCategory,
      user,
      settings,
    } = this.props;
    const { variationError, goodId } = this.state;
    const { productDetail } = product;

    let mainDescriptionAvailable = false;

    const productTabs: TabItem[] = [];

    if (
      product.productDetail !== null &&
      typeof product.productDetail.publish[0]?.content?.json_content?.body ===
        'string' &&
      product.productDetail.publish[0]?.content?.json_content.body !== '' &&
      removeHtmlTags(
        product.productDetail.publish[0]?.content?.json_content?.body,
      ) !==
        product.productDetail.publish[0]?.content?.json_content
          ?.short_description
    ) {
      mainDescriptionAvailable = true;
      productTabs.push({
        name: __('Popis produktu'),
        body: <ProductDescription product={product} />,
      });
    }

    if (
      productDetail?.publish &&
      productDetail.publish[0]?.content?.downloads?.length
    ) {
      productTabs.push({
        name: __('Na stiahnutie'),
        body: <ProductDownloads product={product} className="detail" />,
      });
    }

    const items: any = [];
    if (product && productDetail?.productCategoriesBreadcrumbs?.length > 0) {
      const stopCategoryIds: number[] = [];
      let brEnabled = true;
      if (currentCategoryGroup) {
        for (const fullCategoryGroupCategory of currentCategoryGroup.categories) {
          stopCategoryIds.push(fullCategoryGroupCategory.category_id);
        }

        items.push({
          name: capitalizeFirstLetter(currentCategoryGroup.name),
          url: currentCategoryGroup.uniqid_url,
        });
      }

      if (stopCategoryIds.length) {
        brEnabled = false;
      }

      for (const productCategory of productDetail.productCategoriesBreadcrumbs) {
        if (stopCategoryIds.includes(productCategory.category_id)) {
          brEnabled = true;
        }

        if (brEnabled) {
          items.push({
            name: capitalizeFirstLetter(productCategory.category_name),
            url: items.length !== 0 ? productCategory.url : null,
          });
        }
      }
      if (currentCategoryGroup) {
        items.splice(0, 1, {
          name: capitalizeFirstLetter(currentCategoryGroup.name),
          url: currentCategoryGroup.uniqid_url,
        });
      }
    }

    if (product && productDetail) {
      items.push({
        name: productDetail.name,
        url: productDetail.url,
      });
    }

    let propPath = null;
    if (product && productDetail?.productCategories?.length > 0) {
      propPath = productDetail.productCategories[0].url;
    }

    const outOfStock = isOutOfStock(productDetail);

    let isLinkedProductsAvailable = false;
    if (
      this.props.product.linkedProducts &&
      this.props.product.linkedProducts.length > 1
    ) {
      for (const linkedProduct of this.props.product.linkedProducts) {
        if (!isOutOfStock(linkedProduct)) {
          isLinkedProductsAvailable = true;
          break;
        }
      }
    }

    return (
      <MediaContextProvider>
        {this.state.favoriteProductAddedModalVisible ? (
          <FavoriteAddedModal
            title={__('Pridané do obľúbených produktov')}
            bodyText={
              <>
                <span>
                  {' '}
                  {__(
                    'Produkt úspešne pridaný do vaších obľúbených produktov.',
                  )}{' '}
                </span>
                <br />
                <span>
                  {' '}
                  {__('Prehľad obľúbených produktov nájdete v sekcii')}{' '}
                  <strong>
                    {' '}
                    {__('Môj účet')} /{' '}
                    <FavoriteModalLink href="/my-account/favorite-products">
                      {__('Obľúbené produkty')}
                    </FavoriteModalLink>{' '}
                  </strong>{' '}
                </span>
              </>
            }
            buttonText={__('Pokračovať')}
            buttonPositionDesktop="right"
            titleFontWeight="bold"
            onCloseButtonClick={this.onFavouriteCancel}
            onButtonClick={this.onFavouriteCancel}
          />
        ) : (
          ''
        )}

        {this.state.favoriteMustLoginModalVisible ? (
          <FavoriteMustLoginModal
            title={__('Potrebné prihlásenie')}
            bodyText={
              <>
                <span>
                  {' '}
                  {__(
                    'Aby ste mohli pridať produkt do vašich obľúbených produktov, je potrebné byť prihlásený do vašeho daffer účtu. Ak ešte nemáte svoj daffer účet, môžete sa',
                  )}{' '}
                  <strong>
                    {' '}
                    <FavoriteModalLink href="/registracia">
                      {' '}
                      {__('zaregistrovať tu')}
                    </FavoriteModalLink>
                  </strong>
                  .{' '}
                </span>
              </>
            }
            button1Text={__('Zrušiť pridanie')}
            button2Text={__('Prihlásenie')}
            onButton1Click={this.onFavouriteCancel}
            onButton2Click={this.onFavouriteLogin}
            onCloseButtonClick={this.onFavouriteCancel}
          />
        ) : (
          ''
        )}

        <ProductContainer className="base">
          <MetaTags tags={productMetaHandler(productDetail, 'Daffer.sk')} />
          <Row>
            <Media greaterThanOrEqual="lg">
              <CategoriesCol>
                {isCartGiftsValid(settings, user) &&
                currentCategoryGroup?.uniqid !== 'FURNITURE_SCHOOL' ? (
                  <GiftImage
                    src={getGiftCategoryDesktopBannerFromSettings(settings)}
                    onClick={this.onGiftOpen}
                  />
                ) : (
                  <></>
                )}

                {currentCategoryGroup && currentCategoryGroup.categories ? (
                  <CategoriesListNew
                    dispatch={dispatch}
                    propsPath={propPath}
                    reloadState={true}
                    categoryGroup={currentCategoryGroup.categories}
                  />
                ) : (
                  <CategoriesListNew
                    dispatch={dispatch}
                    reloadState={true}
                    categoryGroup={mainGroupCategory}
                  />
                )}
              </CategoriesCol>
            </Media>

            <MainColStyled>
              <Breadcrumbs items={items} />
              <ProductRow>
                <GalleryCol>
                  <ProductGallery product={product} user={user} />
                </GalleryCol>
                <ProductMetaCol>
                  <ProductBody>
                    <ProductTitle product={product} />
                    {productDetail ? (
                      <>
                        <ProductShortDescription
                          product={productDetail}
                          className="detail"
                        />
                        {mainDescriptionAvailable ? (
                          <DescriptionButton
                            onClick={this.onDescriptionButtonClick}
                          >
                            <InfoIcon icon="info" />{' '}
                            {__('VIAC INFO O PRODUKTE')}
                          </DescriptionButton>
                        ) : (
                          <></>
                        )}
                        <div>
                          <b>Kód: </b>{' '}
                          {productDetail?.product_plu?.startsWith('S')
                            ? `${productDetail?.product_plu}-${productDetail?.product_ean}`
                            : productDetail?.product_plu}
                        </div>
                        {productDetail.on_demand ? (
                          <ProductDemandVariantSelect
                            product={productDetail}
                            onChange={this.onDemandVariationChange}
                            isError={variationError}
                            attribDemandObj={this.state.attribDemandObj}
                          />
                        ) : (
                          <ProductVariantSelect
                            product={productDetail}
                            onChange={this.onVariationChange}
                            isError={variationError}
                            attribsObject={this.state.attribObj}
                          />
                        )}
                        {isLinkedProductsAvailable ? (
                          <AlternativesWrapper>
                            <div>
                              <b> {__('Ďalšie farby/motívy:')} </b>
                            </div>
                            <ProductAlternatives product={product} />
                          </AlternativesWrapper>
                        ) : (
                          <></>
                        )}

                        {!outOfStock || productDetail?.on_demand ? (
                          <>
                            <ProductPrice
                              product={product}
                              user={this.props.user}
                              cart={this.props.cart}
                            />
                            {productDetail && !productDetail.on_demand && (
                              <AvailabilityDetail
                                dispatch={this.props.dispatch}
                                productDetail={productDetail}
                              />
                            )}
                            <ProductForm
                              count={this.state.count}
                              goodId={goodId}
                              product={product}
                              dispatch={dispatch}
                              onCountChange={this.onCountChange}
                              showModal={this.onAddedToCart}
                              setVariationError={this.setVariationError}
                              cart={cart}
                              attribDemandObj={this.state.attribDemandObj}
                            />
                            <ProductFavorite
                              isFavorite={
                                productDetail
                                  ? productDetail.is_user_favorite
                                  : false
                              }
                              onAddClick={this.onAddFavouriteClick}
                              onRemoveClick={this.onRemoveFavouriteClick}
                            />
                            {this.props.upsell ? (
                              <ProductUpsell
                                originalProduct={this.props.product}
                                upsell={this.props.upsell}
                                dispatch={this.props.dispatch}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          productDetail &&
                          !productDetail.on_demand && (
                            <AvailabilityDetail
                              dispatch={this.props.dispatch}
                              productDetail={productDetail}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </ProductBody>
                </ProductMetaCol>
              </ProductRow>
              {productTabs && productTabs.length ? (
                <Tabs
                  items={productTabs.map((p, index) => {
                    return { ...p, id: index };
                  })}
                  activeId={this.state.activeTab}
                  onTabChange={this.onTabChange}
                  className="product-tabs"
                />
              ) : (
                ''
              )}
              {process.env.REACT_APP_FACEBOOK_APP_ID &&
              window?.location?.href ? (
                <FacebookWrapper>
                  <FacebookProvider
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  >
                    <Like
                      href={window.location.href}
                      colorScheme="dark"
                      showFaces={true}
                      share={true}
                    />
                  </FacebookProvider>
                </FacebookWrapper>
              ) : (
                ''
              )}
              {this.props.relatedProducts?.products?.length ? (
                <ProductsSlider
                  title={__('Súvisiace produkty')}
                  products={this.props.relatedProducts.products}
                />
              ) : (
                ''
              )}
              {this.props.loadedLastSeenProducts?.products?.length ? (
                <ProductsSlider
                  title={__('Naposledy zobrazené položky')}
                  products={this.props.loadedLastSeenProducts.products}
                />
              ) : (
                ''
              )}
            </MainColStyled>
          </Row>
        </ProductContainer>
      </MediaContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCartProcessing: state.cart.isProcessing,
    lang: state.general.lang,
    defaultTitle: state.general.title,
    activeVariant: activeVariantByIdSelector(state),
    product: state.product,
    relatedProducts: prop(state.product, 'relatedProducts', {}),
    loadedLastSeenProducts: prop(state.product, 'loadedLastSeenProducts', {}),
    upsell: prop(state.product, 'upsell', null),
    user: state.auth.user,
    cart: state.cart.cart,
    currentCategoryGroup: state.general.currentCategoryGroup,
    mainGroupCategory: state.general.mainGroupCategory,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    settings: state.general.settings,
    currentCategoryId: prop(state, 'general.currentCategoryId'),
  };
};

export default connect(mapStateToProps)(Product);
