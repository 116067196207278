import * as React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../_helpers/Icon/Icon';
import { Link } from 'react-router';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import AnimateHeight from 'react-animate-height';
import {
  capitalizeFirstLetter,
  getBaseUrl,
  getUrl,
  inForSchools,
  prop,
} from '../../../utilities';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { Media, MediaContextProvider } from '../../../theme/media';
import {
  URL_LOGIN,
  URL_REGISTRATION_COMPANY,
  URL_REGISTRATION_RETAIL,
  URL_REGISTRATION_SCHOOL,
  URL_SETS,
} from '../../../constants';
import { push } from 'react-router-redux';

interface NavigationProps {
  items: any[];
  currentPath?: string;
  categoriesGroup?: any[];
  onClickLink: () => void;
  shopDetail?: any;
  shopNetworkCurrentArticle?: any;
  user: any;
  dispatch: any;
  currentUniqs: string[];
}

function NavigationNew({
  items = [],
  currentPath = '',
  categoriesGroup = [],
  onClickLink,
  shopDetail = null,
  shopNetworkCurrentArticle = null,
  user = null,
  dispatch = null,
  currentUniqs = [],
}: NavigationProps) {
  const [map, setMap] = React.useState<any>({});

  React.useEffect(() => {
    if (currentPath && items && categoriesGroup && categoriesGroup.length) {
      const idPath = getPath(items, currentPath);
      const categoryIdPath = getCategoryPath(categoriesGroup, currentPath);

      if (idPath) {
        const newMap = {};
        for (const item of idPath) {
          newMap[`${item}`] = true;
        }

        setMap(newMap);
      } else if (categoryIdPath) {
        const newMap = {};
        for (const item of categoryIdPath) {
          newMap[`${item}`] = true;
        }
        newMap[`0`] = true;
        setMap(newMap);
      } else if (currentPath === '' || currentPath === '/') {
        if (Object.keys(map).length) {
          setMap({});
        }
      }
    }
  }, [items, categoriesGroup]);

  function isCurrentPath(path) {
    return currentPath.startsWith(path);
  }

  function isAccountPath(): boolean {
    return (
      isCurrentPath('/my-account') ||
      isCurrentPath(URL_LOGIN) ||
      isCurrentPath(URL_REGISTRATION_RETAIL) ||
      isCurrentPath(URL_REGISTRATION_COMPANY) ||
      isCurrentPath(URL_REGISTRATION_SCHOOL)
    );
  }

  function isShopNetwork() {
    return isShopDetail() || isArticle();
  }

  function isShopDetail() {
    return !!(shopDetail && currentPath.startsWith(getBaseUrl(shopDetail.url)));
  }

  function isArticle() {
    return !!(
      shopNetworkCurrentArticle &&
      currentPath.startsWith(getBaseUrl(shopNetworkCurrentArticle.url))
    );
  }

  function isCMS(item) {
    if (currentUniqs && currentUniqs.length) {
      return currentUniqs.includes(item.uniqid);
    }

    return (
      isCurrentPath(getBaseUrl(item.url)) ||
      (item.children &&
        item.children.some(
          (c) =>
            getUrl(c.url) === currentPath ||
            (c.children &&
              c.children.some((v) => getUrl(v.url) === currentPath)),
        )) ||
      (item.uniqid === 'SHOP_NETWORK' &&
        isShopNetwork() &&
        inForSchools(
          currentPath,
          [],
          item.uniqId === 'SCHOOLS' ? item.children : null,
        ))
    );
  }

  function onToggleSubmenu(i) {
    if (i === null) {
      return;
    }
    const newMap = { ...map };
    newMap[`${i}`] = !map[i];
    setMap(newMap);
  }

  function onClickLinkInternal(url, id, index) {
    if (id) {
      const idPath = getPath(items, id);
      if (idPath) {
        const newMap = {};
        for (const item of idPath) {
          newMap[`${item}`] = true;
        }
        setMap(newMap);
      }
    } else {
      setMap({});
    }

    if (dispatch && url) {
      dispatch(push(url));
      dispatch(onClickLink);
    }
  }

  function getPath(object, search) {
    if (
      object.id == search ||
      object.url == search ||
      object.link == search ||
      '/' + object.url == search
    )
      return [object.id];
    else if (object.children || Array.isArray(object)) {
      let children = Array.isArray(object) ? object : object.children;
      for (let child of children) {
        let result = getPath(child, search);
        if (result) {
          if (object.id) result.unshift(object.id);
          return result;
        }
      }
    }
  }

  function getCategoryPath(object, search) {
    if (
      object.id == search ||
      object.url == search ||
      object.link == search ||
      '/' + object.url == search
    ) {
      if (object.id) {
        return [object.id];
      } else if (object.category_id) {
        return [object.category_id];
      }
    } else if (object.children || object.categories || Array.isArray(object)) {
      let children = Array.isArray(object)
        ? object
        : object.categories
        ? object.categories
        : object.children;

      for (let child of children) {
        let result = getCategoryPath(child, search);
        if (result) {
          if (object.id) result.unshift(object.id);
          else if (object.category_id) result.unshift(object.category_id);
          return result;
        }
      }
    }
  }

  function getItemId(item, index) {
    return /*index.toString() + */ String(item.id || item.category_id || '');
  }

  function getSubList(subItem, i, depth) {
    const url = getUrl(subItem.url);
    const subItemId = getItemId(subItem, i);
    const isCurrent = map[subItemId] || url === currentPath;

    let children = [];
    if (subItem.children && subItem.children.length) {
      children = subItem.children;
    } else if (subItem.categories && subItem.categories.length) {
      children = subItem.categories;
    }
    const hasChildren = children && children.length;
    if (hasChildren) {
      return (
        <SubItem key={subItemId}>
          <DivLink
            className={isCurrent ? `list-item-${depth}` : `list-item-${depth}`}
            items={children}
            onClick={onToggleSubmenu.bind(null, subItemId)}
          >
            {subItem.url && false ? ( // disabled click on items with children
              <SubNameLink
                className={`list-item-${depth}`}
                onClick={() =>
                  onClickLinkInternal(
                    url,
                    String(subItem.id || subItem.category_id || ''),
                    i,
                  )
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: capitalizeFirstLetter(subItem.name),
                  }}
                ></div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: capitalizeFirstLetter(subItem.category_name),
                  }}
                ></div>
              </SubNameLink>
            ) : (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: subItem.name,
                  }}
                ></div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: subItem.category_name,
                  }}
                ></div>
              </>
            )}
            <ItemCaret icon="arrow" className={isCurrent ? 'current' : ''} />
          </DivLink>
          {isCurrent ? (
            <SubListWrapper height={isCurrent ? 'auto' : '0'}>
              <SubList className="S1">
                {children.map((childItems) => {
                  return getSubList(childItems, ++i, depth + 1);
                })}
              </SubList>
            </SubListWrapper>
          ) : (
            ''
          )}
        </SubItem>
      );
    } else {
      if (subItem.link && subItem.link.startsWith('http')) {
        return (
          <SubItem key={subItemId}>
            <SubLinkExternal
              href={subItem.link}
              target={'_blank'}
              className={
                isCurrent ? `current list-item-${depth}` : `list-item-${depth}`
              }
              items={children}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: capitalizeFirstLetter(subItem.name),
                }}
              ></div>

              <div
                dangerouslySetInnerHTML={{
                  __html: capitalizeFirstLetter(subItem.category_name),
                }}
              ></div>
            </SubLinkExternal>
          </SubItem>
        );
      } else {
        return (
          <SubItem key={subItemId}>
            <SubLink
              className={
                isCurrent
                  ? `current list-item-${depth}`
                  : `tuuuuu sooooom list-item-${depth}`
              }
              items={children}
              to={getUrl(subItem.url)}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: capitalizeFirstLetter(subItem.name),
                }}
              ></div>

              <div
                dangerouslySetInnerHTML={{
                  __html: capitalizeFirstLetter(subItem.category_name),
                }}
              ></div>
            </SubLink>
          </SubItem>
        );
      }
    }
  }

  let id = 0;

  return (
    <MediaContextProvider>
      <Media lessThan="lg">
        <List>
          {categoriesGroup && categoriesGroup.length ? (
            <>
              <Item key={'-1'}>
                <DivLink
                  onClick={() => onClickLinkInternal(URL_SETS, null, null)}
                  className={`${
                    currentPath === URL_SETS ? 'current' : ''
                  } skvelko`}
                >
                  <ItemIcon icon="skvelko" />
                  <ItemText
                    className={currentPath !== URL_SETS ? 'skvelko' : ''}
                  >
                    {__('Zoznamy pomôcok na nový školský rok - Skvelko')}
                  </ItemText>
                  <ItemCaret icon="arrow" />
                </DivLink>
              </Item>
              <Item key={id.toString()}>
                <DivLink
                  className={currentPath === '/' ? 'current' : ''}
                  onClick={onToggleSubmenu.bind(null, id.toString())}
                >
                  <ItemIcon icon="mouse" />
                  <ItemText>{__('Internetový obchod')}</ItemText>
                  <ItemCaret
                    icon="arrow"
                    className={map[id.toString()] ? 'current' : ''}
                  />
                </DivLink>
                <SubListWrapper height={map[id.toString()] ? 'auto' : '0'}>
                  <SubList className="S2">
                    {categoriesGroup.map((subItem) => {
                      return getSubList(subItem, ++id, 1);
                    })}
                  </SubList>
                </SubListWrapper>
              </Item>
            </>
          ) : (
            ''
          )}
          {items &&
            items.map((item) => {
              const icon = getIcon(item);
              const itemId = getItemId(item, id);
              const isCurrent = map[itemId];
              const hasChildren = item.children && item.children.length;

              return (
                <Item key={itemId}>
                  {hasChildren ? (
                    <DivLink
                      className={isCurrent ? '' : ''}
                      onClick={onToggleSubmenu.bind(null, itemId)}
                    >
                      {icon && <ItemIcon icon={icon} />}
                      <ItemText
                        dangerouslySetInnerHTML={{
                          __html: capitalizeFirstLetter(item.name),
                        }}
                      ></ItemText>
                      <ItemCaret
                        icon="arrow"
                        className={isCurrent ? 'current' : ''}
                      />
                    </DivLink>
                  ) : (
                    <DivLink
                      className={isCurrent ? 'current' : ''}
                      onClick={() =>
                        onClickLinkInternal(
                          getUrl(item.link) || getUrl(item.url),
                          String(item.id || item.category_id || ''),
                          null,
                        )
                      }
                    >
                      {icon && <ItemIcon icon={icon} />}
                      <ItemText
                        dangerouslySetInnerHTML={{
                          __html: capitalizeFirstLetter(item.name),
                        }}
                      ></ItemText>
                      <ItemCaret
                        icon="arrow"
                        className={isCurrent ? 'current' : ''}
                      />
                    </DivLink>
                  )}
                  {hasChildren ? (
                    <SubListWrapper height={isCurrent ? 'auto' : '0'}>
                      <SubList className="S3">
                        {item.children.map((subItem) => {
                          return getSubList(subItem, ++id, 1);
                        })}
                      </SubList>
                    </SubListWrapper>
                  ) : (
                    ''
                  )}
                </Item>
              );
            })}
        </List>
      </Media>
      <Media greaterThanOrEqual="lg">
        <List>
          <Item>
            <ItemLink
              className={
                !items.some((i) => isCMS(i)) && !isAccountPath()
                  ? 'current'
                  : ''
              }
              to="/"
              //onClick={() => onClickLinkInternal('/', null, null)}
            >
              <ItemIcon icon="mouse" />
              {__('Internetový obchod')}
            </ItemLink>
          </Item>
          {items &&
            items.map((item, i) => {
              const icon = getIcon(item);
              return (
                <Item key={i.toString()}>
                  {item.link && item.link.startsWith('https://') ? (
                    <ItemAnchor href={item.link} target="_blank">
                      {icon && <ItemIcon icon={icon} />}
                      <ItemText>{item.name}</ItemText>
                    </ItemAnchor>
                  ) : (
                    <ItemLink
                      className={isCMS(item) ? 'current' : ''}
                      to={getUrl(item.link)}
                      //onClick={() =>
                      //  onClickLinkInternal(getUrl(item.link), null, null)
                      //}
                    >
                      {icon && <ItemIcon icon={icon} />}
                      <ItemText>{item.name}</ItemText>
                    </ItemLink>
                  )}
                </Item>
              );
            })}

          <Item>
            <ItemLink
              href={user ? '/my-account' : URL_LOGIN}
              className={
                !items.some((i) => isCMS(i)) && isAccountPath() ? 'current' : ''
              }
              onClick={() =>
                onClickLinkInternal(
                  user ? '/my-account' : URL_LOGIN,
                  null,
                  null,
                )
              }
            >
              <ItemIcon icon="user2" />
              {user && user.skvelko_sets_count
                ? __('Skvelko účet')
                : __('Môj účet')}
            </ItemLink>
          </Item>
        </List>
      </Media>
    </MediaContextProvider>
  );
}

function getIcon(item: any) {
  if (!item || !item.uniqid) {
    return false;
  }
  switch (item.uniqid) {
    case 'SHOP_NETWORK':
      return 'location2';
    case 'SCHOOLS':
      return 'school';
    case 'ORGANIZATIONS':
      return 'cube';
    case 'HELP_AND_SUPPORT':
      return 'question';
    case 'CONTACT':
      return 'contact';
    case 'ACCOUNT':
      return 'user2';
  }
  return false;
}

const List = styled.ul`
  color: ${({ theme }) => theme.color.white};
  line-height: 1;
  font-weight: 700;
  padding: 0;
  margin: 0;
  list-style: none;
  ${({ theme }) => theme.media('md')} {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  .list-item-1 {
    padding-left: ${rem(40)};
  }

  .list-item-2 {
    padding-left: ${rem(56)};
  }

  .list-item-3 {
    padding-left: ${rem(72)};
  }

  .list-item-4 {
    padding-left: ${rem(88)};
  }

  .list-item-5 {
    padding-left: ${rem(104)};
  }
`;

const ItemLinkStyles = css`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.gray16};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray34};
  color: inherit;
  padding: ${rem(11)} ${rem(32)} ${rem(10)} ${rem(24)};
  position: relative;
  &.skvelko {
    border-top: ${rem(1)} solid ${({ theme }) => theme.color.gray34};
  }
  &:hover {
    text-decoration: none;
  }
  ${({ items }) => {
    if (items) {
      return css`
        padding-right: ${rem(48)};
      `;
    }
    return css``;
  }};
  ${({ theme }) => theme.media('md')} {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.gray89};
    border: none;
    color: ${({ theme }) => theme.color.gray14};
    font-size: ${rem(14)};
    text-transform: uppercase;
    padding: ${rem(9)} ${rem(5)};
    height: 100%;

    &:hover {
      background: ${({ theme }) => theme.color.secondary};
    }
  }
  @media (min-width: 992px) and (max-width: 1084px) {
    font-size: 0.775rem;
  }
`;

const DivLink = styled.div`
  ${ItemLinkStyles};
  &.current {
    background: ${({ theme }) => theme.color.primary};
  }
  ${({ theme }) => theme.media('md')} {
    &.current {
      background: ${({ theme }) => theme.color.secondary};
    }
  }
`;

const ItemLink = styled(Link)`
  ${ItemLinkStyles};
  &.current {
    background: ${({ theme }) => theme.color.primary};
  }
  ${({ theme }) => theme.media('md')} {
    &.current {
      background: ${({ theme }) => theme.color.secondary};
    }
  }
`;

const ItemAnchor = styled.a`
  ${ItemLinkStyles};
`;

const ItemText = styled.span`
  display: inline-block;
  &.skvelko {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

const Item = styled.li`
  cursor: pointer;
  ${({ theme }) => theme.media('md')} {
    flex: 1 1 auto;
    &:first-child {
      ${ItemAnchor},
      ${ItemLink} {
        border-bottom-left-radius: ${({ theme }) =>
          rem(theme.borderRadius.default)};
      }
    }
    &:last-child {
      ${ItemAnchor},
      ${ItemLink} {
        border-bottom-right-radius: ${({ theme }) =>
          rem(theme.borderRadius.default)};
      }
    }
  }
`;

const ItemIcon = styled(Icon)`
  fill: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.size(20)};
  vertical-align: middle;
  margin-right: ${rem(9)};
  margin-left: ${rem(-15)};
  ${({ theme }) => theme.media('md')} {
    fill: ${({ theme }) => theme.color.gray14};
    margin: 0 ${rem(7)} 0 0;
  }
`;

const ItemCaret = styled(Icon)`
  fill: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.size(12)};
  position: absolute;
  top: 50%;
  right: ${rem(15)};
  transform: translate3d(0, -50%, 0);
  ${({ theme }) => theme.media('md')} {
    display: none;
  }
  transition-duration: 0.2s;
  &.current {
    transform: rotate(90deg) translateX(${rem(-5)});
  }
`;

const SubListWrapper = styled(AnimateHeight)``;

const SubList = styled(List)`
  font-weight: 400;
  ${({ theme }) => theme.media('md')} {
    display: none;
  }
`;

const SubItem = styled(Item)``;

const SubLinkStyles = css`
  ${ItemLinkStyles};
  background: ${({ theme }) => theme.color.black};
  &.current {
    background: ${({ theme }) => theme.color.primary};
  }
  ${({ theme }) => theme.media('md')} {
    &.current {
      background: ${({ theme }) => theme.color.secondary};
    }
  }
  ${({ items }) => {
    if (items) {
      return css`
        padding-right: ${rem(48)};
      `;
    }
    return css``;
  }};
`;

const SubLink = styled(Link)`
  ${SubLinkStyles};
`;

const SubLinkExternal = styled.a`
  ${SubLinkStyles};
`;

const SubNameLink = styled(Link)`
  color: inherit;
  position: relative;
  &:hover {
    text-decoration: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    shopDetail: prop(state, 'shopDetail.shopDetail', null),
    shopNetworkCurrentArticle: prop(
      state,
      'general.shopNetworkCurrentArticle',
      null,
    ),
    currentUniqs: prop(state, 'general.currentUniqs', []),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NavigationNew' })(NavigationNew),
);
