import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import styled, { css } from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { Field, Form } from 'react-final-form';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { __ } from 'react-i18n';
import { required } from '../../utilities/validators';
import { Content } from '../../components/_layout/Page/ContentContainer';
import { rem } from 'polished';
import Icon from '../../components/_helpers/Icon/Icon';
import { CartHeading } from '../../components/Cart/CartStyles';
import {
  Button,
  ButtonStyledStyles,
} from '../../components/_helpers/form/Button';
import { getBaseUrl } from '../../utilities';

const MainContainer = styled.div``;
const RegistrationErrorPopup = styled(ErrorPopup)``;

const QuestionsWrapper = styled.div`
  background: ${(props) => (props.isOdd ? '#f4f4f4' : '#ffffff')};
  padding-right: 16px;
  padding-left: 16px;
`;

const QuestionRow = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 700px;
  margin: auto;
`;

const QuestionContent = styled.div`
  padding: 10px;
`;

const SurveyText = styled.div`
  margin-bottom: 16px;
`;

const QuestionText = styled.div`
  font-weight: bold;
  font-size: 120%;
`;

const RadioInputWrapper = styled.div``;

const OptionWrapper = styled.div``;

const OptionInputWrapper = styled.div``;

const QuestionTextArea = styled.textarea``;

const ThankYouWrapper = styled(Content)`
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const ThankYouHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(24)};
`;

const ThankYouHeadingIconStyles = css`
  ${({ theme }) => theme.size(55)};
  fill: ${({ theme }) => theme.color.success};
  &.error {
    fill: ${({ theme }) => theme.color.error};
  }
`;

const ThankYouHeadingIcon = styled(Icon)`
  ${ThankYouHeadingIconStyles};
  margin-right: ${rem(15)};
`;

const ThankYouHeading = styled(CartHeading)`
  margin: 0;
`;

export const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;

export const SendButton = styled(Button)`
  ${ButtonStyledStyles};
  min-width: 400px;
  &.lg {
    padding-right: ${rem(20)};
    padding-left: ${rem(20)};
  }
`;

export interface SuveryProps {
  params: {
    orderPublicId: string;
  };
  setTitleText: (title: string) => void;
  setClosePath: (path: string) => void;
}

const Survey = (props: SuveryProps) => {
  //const [loading, setLoading] = React.useState<boolean>(true);
  const [thankYouActive, setThankYouActive] = React.useState<boolean>(false);
  const [survey, setSurvey] = React.useState<ThenArg<typeof API.loadSurvey>>();

  React.useEffect(() => {
    props.setTitleText(__('Dotazník spokojnosti'));
    props.setClosePath(getBaseUrl('/'));
    //setLoading(true);

    API.checkSurveyStatus(props.params.orderPublicId).then((response) => {
      if (response.status === true) {
        setThankYouActive(true);
      } else {
        console.log('ORDER PUBLIC ID');
        console.log(props.params.orderPublicId);

        API.loadSurvey({ orderPublicId: props.params.orderPublicId }).then(
          (survey) => {
            setSurvey(survey);
            //setLoading(false);
          },
        );
      }
    });
  }, []);

  const onSubmit = async (values) => {
    API.uploadSurveyAnswers(
      {},
      {
        order_public_id: props.params.orderPublicId,
        answers: values,
      },
    ).then(() => {
      setThankYouActive(true);
    });
  };

  if (thankYouActive) {
    return (
      <ThankYouWrapper>
        <ThankYouHeadingWrapper>
          <ThankYouHeadingIcon icon="checkCircle" />
          <ThankYouHeading>{__('Ďakujeme za hodnotenie')}</ThankYouHeading>
        </ThankYouHeadingWrapper>
      </ThankYouWrapper>
    );
  } else {
    return (
      <MainContainer className="base">
        {survey ? (
          <>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                  {survey.questions.map((question, i) => {
                    return (
                      <QuestionsWrapper isOdd={i % 2 === 0}>
                        <QuestionRow>
                          {i == 0 ? (
                            <SurveyText>
                              {__(
                                'Vážený zákazník e-shopu www.daffer.sk, budeme nesmierne radi, ak nám Vašimi odpoveďami v tomto 2-minútovom prieskume pomôžete zlepšiť naše služby.',
                              )}
                            </SurveyText>
                          ) : (
                            <></>
                          )}
                          <QuestionText>
                            {question.question}
                            {question.required ? '*' : ''}
                          </QuestionText>
                          <QuestionContent>
                            {question.type == 1 ? (
                              <Field
                                name={`question_${question.id}`}
                                validate={
                                  question.required ? required : undefined
                                }
                              >
                                {({ input, meta }) => (
                                  <RadioInputWrapper>
                                    {question.options?.map((option) => {
                                      return (
                                        <OptionWrapper>
                                          <input
                                            name={input.name}
                                            type="radio"
                                            value={option.id}
                                            checked={input.checked}
                                            onChange={input.onChange}
                                          />
                                          <label> {option.option} </label>
                                          {option.type === 2 ? (
                                            <OptionInputWrapper>
                                              <Field
                                                name={`option_input_${option.id}`}
                                              >
                                                {({ input, meta }) => (
                                                  <input {...input}></input>
                                                )}
                                              </Field>
                                            </OptionInputWrapper>
                                          ) : (
                                            <></>
                                          )}
                                        </OptionWrapper>
                                      );
                                    })}
                                    {meta.error && meta.touched && (
                                      <RegistrationErrorPopup>
                                        {meta.error}
                                      </RegistrationErrorPopup>
                                    )}
                                  </RadioInputWrapper>
                                )}
                              </Field>
                            ) : (
                              <></>
                            )}
                            {question.type == 2 ? (
                              <Field name={`question_${question.id}`}>
                                {({ input, meta }) => (
                                  <QuestionTextArea
                                    cols={50}
                                    {...input}
                                  ></QuestionTextArea>
                                )}
                              </Field>
                            ) : (
                              <></>
                            )}
                          </QuestionContent>
                        </QuestionRow>
                      </QuestionsWrapper>
                    );
                  })}
                  <SendButtonWrapper>
                    <SendButton className="primary lg" type="submit">
                      Odoslať
                    </SendButton>
                  </SendButtonWrapper>
                </form>
              )}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </MainContainer>
    );
  }
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Survey' })(Survey),
);
